import {
  FiInstagram,
  FiFacebook,
  FiMail,
  FiLinkedin,
  FiTwitter,
} from "react-icons/fi";

import { RiMessengerLine, RiWhatsappLine } from "react-icons/ri";

import styles from "./SocialLinks.module.scss";

export default function SocialLinks({
  links,
  omit = [],
  include = [],
  iconColor = "var(--dark-text)",
  itemStyles,
}) {
  const backupLinks = {
    email: "contact@wherenext.to",
    facebook: "https://www.facebook.com/wherenext.to.travel",
    instagram: "https://www.instagram.com/wherenext.to/",
    linkedin: "https://www.linkedin.com/company/wherenext-to/",
    messenger: "http://m.me/wherenext.to.travel",
    twitter: "https://twitter.com/wherenext_to",
    whatsapp: "https://wa.me/61401952975",
  };

  const iconConfig = { size: 25, color: iconColor };

  const socialIcons = Object.entries(links ?? backupLinks)
    .filter((entry) => {
      if (entry[0] === "id" || entry[0] === "__typename") {
        return false;
      } else if (!entry[1]) {
        return false;
      } else if (!omit.length && !include.length) {
        return true;
      } else if (omit.length > 0) {
        return !omit.includes(entry[0]);
      } else if (include.length > 0) {
        return include.includes(entry[0]);
      }

      return false;
    })
    .map((entry, index) => {
      // if (omit.includes(entry[0])) {
      //   return;
      // }

      let icon;

      switch (entry[0]) {
        case "facebook":
          icon = <FiFacebook {...iconConfig} />;
          break;
        case "instagram":
          icon = <FiInstagram {...iconConfig} />;
          break;
        case "email":
          icon = <FiMail {...iconConfig} />;
          break;
        case "linkedin":
          icon = <FiLinkedin {...iconConfig} />;
          break;
        case "messenger":
          icon = <RiMessengerLine {...iconConfig} />;
          break;
        case "twitter":
          icon = <FiTwitter {...iconConfig} />;
          break;
        case "whatsapp":
          icon = <RiWhatsappLine {...iconConfig} />;
          break;
      }

      return (
        <li
          key={`${entry[0]}-${index}`}
          className={`${styles.linkItem}`}
          style={itemStyles}
        >
          <a
            href={`${entry[0] === "email" ? "mailto:" : ""}${entry[1]}`}
            target="_blank"
            rel="noreferrer"
            aria-label={`Link to ${entry[0]}`}
            className={`${styles.link}`}
          >
            {icon}
          </a>
        </li>
      );
    });

  return <ul className={`${styles.socialLinks}`}>{socialIcons}</ul>;
}
